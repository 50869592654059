import { objectToFormData } from "@/utils/objectToFormData";

export const getJson = (data) => ({
    id: data.id,
    collection_name: data.collection_name,
    thumbnail: data.thumbnail==null ? data.image : data.thumbnail ,
    thumbnail_w300: data.thumbnail_w300,
    image: data.image,
    mime_type: data.mime_type,
    file_name: data.file_name,
    size: data.size,
    url: data.url
});

export const setData = (data) => (
    objectToFormData({
        file: data.file,
        batch_id: data.batch_id,
        url:data.url,
        model_id : data.model_id,
        model_name: data.model_name,
        crop: data.crop,
        collection_name: data.collection_name ? data.collection_name : 'main_image'
    }));
