<template>
  <div class="social__title">
    <div>{{ title }}</div>
  </div>
</template>
<script>
export default {
  props: {
    title: {},
  },
};
</script>
<style scoped>
.social__title div {
  font-family: "Barlow-EL";
  font-size: 30px;
  text-transform: uppercase;
  border-left: 3px solid var(--color-black);
  padding-left: 20px;
  line-height: 30px;
  margin-bottom: 20px;
  font-weight: 700;
}
@media (max-width: 600px) {
  .social__title div {
    font-size: 20px;
    padding-left: 10px;
  }
}
</style>
