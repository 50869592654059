<template>
  <div>
    <template>
      <v-container class="py-1 d-none d-md-block contain__icon">
        <div>
          <SocialIcon />
        </div>
      </v-container>
      <Haeder />
      <div class="wa__contain">
        <main class="pb-13">
          <div class="pt-9 social__gray">
            <SocialDivider :title="$_t('title')" :route="true" />
          </div>
          <v-container class="pa-4 pa-md-0" v-if="!isSended">
            <!-- v-if="form.model_ids && form.model_ids.length && !isSended" -->
            <div class="mt-16 px-0 px-sm-5">
              <div class="d-flex justify-start items">
                <template v-if="form.model_ids && form.model_ids.length">
                  <div
                    v-for="modelData in models"
                    :key="modelData.id"
                    class="pr-sm-3 mt-2 mt-sm-0 item"
                  >
                    <div class="position-relative wa__tile--link">
                      <span class="delete"
                        ><v-icon @click.native="removeRequest(modelData.id)" color="black"
                          >mdi-close</v-icon
                        ></span
                      >
                      <a
                        :href="`${$baseUrl}${$_getlocaleParam() ? '/' : ''}${
                          $_getlocaleParam() ? $_getlocaleParam() : ''
                        }/model/${modelData.unique_id}`"
                      >
                        <div>
                          <picture>
                            <img
                              data-async-image="true"
                              :src="modelData.model.thumbnail"
                              class="model__image"
                              decoding="async"
                          /></picture>
                        </div>
                        <!--  -->
                        <div class="model__title text-uppercase mt-2">
                          {{ $_changeModelProperty(modelData, "first_name") }}
                        </div>
                        <div class="model__sub-title">
                          {{ modelData.country.name }}
                          {{ modelData.model.height }} / {{ modelData.model.weight }} /
                          {{ modelData.model.bust }} / {{ modelData.model.waist }} /
                          {{ modelData.model.hips }}
                        </div>
                      </a>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="pr-sm-3 mt-2 mt-sm-0 item add__new">
                    <a :href="localeUrl($baseUrl + `/models`)">
                      <div class="add__new__model">
                        <v-icon>WMi-plus-linear</v-icon>
                      </div>
                    </a>
                  </div>
                </template>
              </div>
            </div>
            <div class="mx-5 px-0 px-sm-2 mt-9">
              <v-row>
                <v-col v-if="!isLogin" cols="12" md="3" class="px-0 px-md-0">
                  <div>
                    <SocialSectionTitle :title="$_t('rightWay.title')" />
                    <div class="text text-caption">
                      {{ $_t("rightWay.subTitle") }}
                    </div>
                    <LoginSectoin :site="true" />
                  </div>
                </v-col>
                <v-col v-resize="onResize" v-if="!isLogin" cols="12" md="1">
                  <div
                    :class="{
                      wa__or__line: windowSize.x < 960,
                      wa__or: windowSize.x > 960,
                    }"
                  >
                    <div class="text-center">{{ $_t("or") }}</div>
                    <div class="wa__or__border"></div>
                  </div>
                </v-col>
                <v-col cols="12" :md="isLoginCol">
                  <div>
                    <SocialSectionTitle v-if="!isLogin" :title="$_t('quickWay.title')" />
                    <div class="text text-caption" v-if="!isLogin">
                      {{ $_t("quickWay.subTitle") }}
                    </div>
                    <div class="mt-6 small-text">
                      {{ $_t("quickWay.text") }}
                    </div>
                    <div class="pr-8 mt-1">
                      <div class="d-flex">
                        <v-item-group multiple v-model="form.model_types">
                          <v-item
                            v-for="modelType in modelTypes"
                            :key="modelType.id"
                            :value="modelType.id"
                            v-slot="{ active, toggle }"
                            class="mr-2"
                          >
                            <block-button
                              :style="
                                active
                                  ? 'color: var(--color-black) !important;background-color: var(--color-light-gray) !important;'
                                  : ''
                              "
                              :text="$_changeModelProperty(modelType, 'name')"
                              :class="[active ? 'active' : '', 'btn-intended mb-2']"
                              @click.native="toggle"
                              height="27"
                              color="gray"
                              bg-color="white"
                            ></block-button>
                          </v-item>
                        </v-item-group>
                      </div>
                    </div>
                    <div class="mt-6 my-3">
                      <v-row>
                        <v-col cols="12" sm="6" class="pt-0 pr-md-1">
                          <date-input
                            :label="$_t('shooting')"
                            color="black"
                            class="b-black"
                            v-model="form.begin_date"
                            outlined
                            dense
                            :min="today"
                          ></date-input>
                        </v-col>
                        <v-col cols="12" sm="6" class="pt-0 pl-md-1">
                          <v-text-field
                            outlined
                            class="b-black"
                            :label="$_t('forHowManyDays')"
                            v-model="form.required_day"
                            color="black"
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pt-0">
                          <v-text-field
                            outlined
                            :label="$_t('location')"
                            class="b-black"
                            v-model="form.city"
                            color="black"
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row class="mt-0">
                        <v-col cols="12" sm="6" class="pt-0">
                          <div>
                            <v-checkbox
                              v-model="form.call_me"
                              class="mt-1"
                              color="black"
                            >
                              <template v-slot:label>
                                <div class="checkbox-label">
                                  {{ $_t("quickWay.checkboxLabel") }}
                                </div>
                              </template>
                            </v-checkbox>
                          </div>
                        </v-col>
                      </v-row> -->
                    </div>
                    <div v-if="!isLogin">
                      <v-row>
                        <v-col cols="12" sm="6" md="4" class="pt-0 pr-md-1">
                          <v-text-field
                            outlined
                            v-model="form.first_name"
                            :label="$_t('firstName')"
                            class="b-black mt-0 pt-0"
                            color="black"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" class="pt-0 px-md-1">
                          <v-text-field
                            outlined
                            v-model="form.last_name"
                            :label="$_t('lastName')"
                            class="b-black mt-0 pt-0"
                            color="black"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" class="pt-0 pl-md-1">
                          <v-text-field
                            outlined
                            v-model="form.corporate"
                            :label="$_t('companyName')"
                            class="b-black mt-0 pt-0"
                            color="black"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" class="pt-0 pr-md-1">
                          <v-text-field
                            outlined
                            v-model="form.email"
                            :label="$_t('emailAddress')"
                            class="b-black mt-0 pt-0"
                            color="black"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" class="pt-0 pl-md-1">
                          <v-text-field
                            outlined
                            :label="$_t('phoneNumber')"
                            v-model="form.cell_phone"
                            class="b-black mt-0 pt-0"
                            color="black"
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <div>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            rows="4"
                            :label="$_t('message')"
                            dense
                            outlined
                            color="black"
                            class="b-black"
                            v-model="form.description"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                    <ul class="d-flex pl-0 list-unstyled mt-3 select_box">
                      <li>
                        <block-button
                          icon="WMi-cloud"
                          color="white"
                          bg-color="black"
                          :text="$_t('selectFile')"
                          class="select-file"
                          @click.native="$refs.fileInput.click()"
                        >
                        </block-button
                        ><input
                          type="file"
                          style="display: none"
                          ref="fileInput"
                          accept="image/*"
                          @change="filePicked"
                        />
                      </li>
                      <li v-if="fileData.name" class="text-subtitle-1 mr-2">
                        {{ fileData.name }}
                      </li>
                      <li class="text-body-2 text-sm-subtitle-1 mt-2 mt-sm-0 mr-2">
                        {{ $_t("quickWay.uploadText") }}
                      </li>
                      <li class="text-caption upload-text mt-1">
                        {{ $_t("quickWay.allowedFormat") }}
                      </li>
                    </ul>
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <div class="float-right">
                        <block-button
                          @click.native="sendHiringRequest"
                          class="site__button width-200px px-sm-16 px-7 btn-send"
                          :loading="loadingFile"
                          height="25"
                          :class="{ disabled: isSended }"
                          :text="$_t('quickWay.sendButton')"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-container>
          <!-- <div
            class="text-center no__moldels"
            v-if="!(form.model_ids && form.model_ids.length) && !isSended"
          >
            <div>
              <div>You did not select any model</div>
              <a
                href="https://lilianamodels.com/models"
                class="text-decoration-none"
              >
                <block-button
                  color="white"
                  bg-color="black"
                  text="Return to main page"
                />
              </a>
            </div>
          </div> -->
          <div v-if="isSended" class="send-status">
            <div>
              <div class="font-secondary title">THANK YOU</div>
              <div>Your Request was Sent. we will be in touch very soon.</div>
            </div>
          </div>
        </main>
      </div>
      <footer>
        <div class="wa__social--footer">
          <SocialFooter />
        </div>
      </footer>
    </template>
  </div>
</template>
<script>
import LoginSectoin from "../components/Global/Section/LoginSection.vue";
import SocialSectionTitle from "../components/Global/Section/SocialSectionTitle.vue";
import SocialDivider from "../components/Global/Section/SocialDivider.vue";
import SocialFooter from "../components/Global/Footer/SocialFooter.vue";
import SocialIcon from "../components/Global/Header/User/SocialIcon.vue";
import Haeder from "../components/Global/Header/User/Header.vue";
import ModelRepository from "../abstraction/repository/modelRepository";
import { mapActions } from "vuex";
import { TokenStorage } from "@/utils/storage";
import { RequestStorage } from "@/utils/requestStorage";
import RequestRepository from "@/abstraction/repository/requestRepository";
import FileRepository from "@/abstraction/repository/FileRepository";
import { makeid } from "@/utils/math";
const RANDOM_TOKEN = makeid(50);
export default {
  components: {
    SocialDivider,
    SocialFooter,
    LoginSectoin,
    SocialSectionTitle,
    SocialIcon,
    Haeder,
  },
  data: () => ({
    inputColor: "#acacac",
    fileData: {},
    loadingFile: false,

    windowSize: {
      x: 0,
    },

    form: {
      file_batch_id: RANDOM_TOKEN,
      model_ids: [],
    },
    models: [],
    modelTypes: [],
    fileForm: {
      batch_id: RANDOM_TOKEN,
      model_name: "request",
      collection_name: "file",
    },
    showPassword: false,
    isLogin: TokenStorage.hasToken(),
    isLoginCol: null,
    isSended: false,
    today: new Date().toISOString().slice(0, 10),
  }),
  methods: {
    ...mapActions("auth", ["login"]),
    async doLogin() {
      const response = await this.login(this.form);
      if (response) {
        this.isLogin = TokenStorage.hasToken();
        this.loadModels();
      }
    },
    async loadModels() {
      this.form.model_ids = RequestStorage.get().model_ids;
      if (this.form.model_ids && this.form.model_ids.length > 0) {
        let repository = new ModelRepository();
        this.models = await repository.indexMultipleModel(this.form.model_ids);
        this.loadModelTypes();
        this.selectModelTypes();
      }
    },
    loadModelTypes() {
      this.models.map((model) => this.modelTypes.push(...model.model_types));
      this.modelTypes = this.modelTypes.filter((val, idx, arr) => {
        return idx === arr.findIndex((x) => x.id === val.id);
      });
    },
    selectModelTypes() {
      if (!this.form.model_types) {
        // select all items
        this.form.model_types = this.modelTypes.map(({ id }) => id);
      } else {
        // update selected items
        this.form.model_types = this.form.model_types.filter((id) => {
          return this.modelTypes.some((x) => x.id === id);
        });
      }
    },
    async sendHiringRequest() {
      try {
        if (!this.form.model_ids || this.form.model_ids.length === 0) return;
        this.loadingFile = true;
        if (this.fileForm.file) {
          let repository = new FileRepository();
          await repository.store(this.fileForm);
        }
        let repository = new RequestRepository();
        await repository.multiStore(this.form);
        this.isSended = true;
        this.form = {};
        RequestStorage.remove();
      } catch (error) {
        return error;
      } finally {
        this.loadingFile = false;
      }
    },
    filePicked(event) {
      this.fileForm.file = event.target.files[0];
      this.fileData = event.target.files[0];
    },
    removeRequest(id) {
      this.removeModel(id);
      this.saveInLocalstorage();
      this.modelTypes = [];
      this.loadModelTypes();
      this.selectModelTypes();
    },
    removeModel(modelId) {
      let index = this.models.findIndex((x) => x.id === modelId);
      this.models.splice(index, 1);
      index = this.form.model_ids.findIndex((x) => x === modelId);
      if (index >= 0) this.form.model_ids.splice(index, 1);
    },
    saveInLocalstorage() {
      const requests = { model_ids: this.form.model_ids };
      RequestStorage.set(requests);
    },
    onResize() {
      this.windowSize = { x: window.innerWidth };
    },
  },
  created() {
    this.isLogin ? (this.isLoginCol = 12) : (this.isLoginCol = 8);
    this.loadModels();
    this.onResize();
  },
};
</script>
<style>
.sf-menu a li.large {
  margin: 0 20px !important;
}
</style>
<style scoped>
@media (min-width: 1904px) {
  /* .container {
    max-width: 1340px !important;
  } */
}
@media screen and (min-width: 600px) and (max-width: 960px) {
  .container {
    max-width: 960px !important;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1340px !important;
  }
}
@media (min-width: 1200px) {
  /* .container {
    max-width: 1140px !important;
  } */
  .contain__icon {
    max-width: 1340px !important;
  }
}
@media (min-width: 992px) {
  /* .container {
    max-width: 960px;
  } */
  .contain__icon {
    max-width: 1340px !important;
  }
}

.wa__social--footer {
  background-color: var(--color-black);
}
.social__gray {
  background-color: #f5f5f5 !important;
}
main {
  min-height: auto !important;
}
.model__image {
  width: 100%;
}
.delete {
  background-color: var(--color-bg-white);
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.9;
  z-index: 0;
  cursor: pointer;
}
.h-inherit {
  height: inherit;
}
.model__title {
  font-family: "Montserrat" !important;
}
.model__sub-title {
  font-family: "Montserrat" !important;
  color: var(--color-gray);
  position: relative;
  top: -0.3rem;
  font-size: 10px;
}
.text {
  color: var(--color-smoky);
  margin-top: 10px;
  font-size: 14px !important;
  font-family: "Montserrat" !important;
  font-weight: 300 !important;
}
.small-text {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: var(--color-gray);
  letter-spacing: 1px !important;
  margin-bottom: 8px;
}

.wa__tile--link a {
  text-decoration: none;
  display: block;
  color: initial;
}
.wa__or {
  height: 100%;
  overflow: hidden;
}
.wa__or__line {
  margin: 15px 0 30px 0;
}
.wa__or .text-center,
.wa__or__line .text-center {
  letter-spacing: 8px;
}
.wa__or .wa__or__border {
  border-left: 1px solid var(--color-light-gray);
  height: 100%;
  width: 6px;
  margin: 0 auto;
}
.wa__or__line .wa__or__border {
  border-bottom: 1px solid var(--color-light-gray);
}
.btn-intended {
  font-family: "montserrat-regular";
  border: 1px solid var(--color-gray) !important;
  font-weight: bold;
  font-size: 12px;
}
.active {
  border: 1px solid var(--color-black) !important;
  font-family: "montserrat" !important;
}
.checkbox-label {
  font-family: "montserrat";
  text-transform: lowercase;
  font-size: 13px;
  margin-top: 9px;
}
.upload-text {
  font-family: "montserrat" !important;
  color: var(--color-gray);
}
.btn-send {
  font-size: 13px !important;
  padding: 0 80px;
  letter-spacing: 3px !important;
  border-radius: 0;
  font-size: 14px !important;
}
.add__new__model {
  position: relative;
  height: 100%;
}
.add__new__model i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
@media (min-width: 1200px) {
  .container {
    max-width: 1440px;
  }
}
.select-file {
  height: 30px !important;
  font-size: 14px;
  padding: 0 16px !important;
}

.select_box {
  flex-wrap: wrap;
}
.select_box .text-subtitle-1 {
  font-family: "montserrat" !important;
}
.select_box li {
  margin: 0 5px;
}
.no__moldels {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 400px;
}
.items {
  flex-wrap: wrap;
}
.items .item {
  width: 16.6666666667%;
  margin-bottom: 20px;
}
.items .item.add__new {
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
  height: 280px;
}
.send-status {
  background-color: #faffff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  color: #32cad5;
  font-size: 16px;
  height: 50vh;
}
.send-status .title {
  text-align: center;
  font-size: 26px;
  text-transform: uppercase;
  letter-spacing: 10px;
}
@media (max-width: 1200px) {
  .items .item {
    width: 20%;
    margin-bottom: 20px;
  }
  .items .item:nth-child(6n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 960px) {
  .items .item {
    width: 25%;
    margin-bottom: 20px;
  }
  .items .item:nth-child(5n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 768px) {
  .items .item {
    width: 33.33333%;
    margin-bottom: 20px;
  }

  .items .item:nth-child(4n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 480px) {
  .items {
    justify-content: center;
  }
  .items .item {
    width: 50%;
    margin-bottom: 10px;
    padding: 10px !important;
  }
  .items .item:nth-child(3n),
  .items .item:nth-child(4n),
  .items .item:nth-child(5n),
  .items .item:nth-child(6n) {
    padding-right: 0 !important;
  }
  .items .item.add__new {
    height: 210px;
  }
}
</style>
