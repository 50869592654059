const REQUESTS = "requests";
import StoreManagement from "./StoreManagement"
const RequestStorage = {
  get() {
    if (StoreManagement.get(REQUESTS)) {
      return StoreManagement.get(REQUESTS);
    }
    return {};
  },

  set(modelData = null) {
    modelData = { ...this.get(), ...modelData };
    StoreManagement.set(REQUESTS, modelData);
  },

  remove() {
    StoreManagement.remove(REQUESTS);
  },
};

export { RequestStorage };
 